import { Customer }          from "@/model/Customer";
import { NewOrder }          from "@/model/Order";
import { UserRoleEnum }      from "@/model/User";
import { authStore }         from "@/modules/auth/store";
import { MenuItem }          from "@/primevue";
import { useGoogleLoader }   from "@/use/useGoogleLoader";
import { classToClass }      from "class-transformer";
import { Options, Vue }      from "vue-class-component";
import { OrdersRoutesEnum }  from "../../router";
import { ordersStoreModule } from "../../store";

function disabeldIf(...requiredFields: (keyof  NewOrder)[]): boolean {
  return requiredFields.some(k => !ordersStoreModule.getters?.newOrder?.[k])
}
@Options({
  beforeRouteEnter() {
    ordersStoreModule.mutations.initNewOrder();
  },

  beforeRouteLeave() {
    if (ordersStoreModule.getters.newOrder) {
      const clone = classToClass(ordersStoreModule.getters.newOrder);
      clone.resetCustomer()
      clone.resetPickup()
      clone.resetDropoff()
      clone.resetOtherDataSection()
      
      clone.note_customer  && delete clone.note_customer
      clone.note_supplier  && delete clone.note_supplier
      clone.note_tmt       && delete clone.note_tmt

      localStorage.setItem(
        'new_order', 
        JSON.stringify(clone)
      );
    }
  }
})
export default class NewOrderPage extends Vue {

  get me() {
    return authStore.getters.me;
  }

  get items(): MenuItem[] {
    return [
      {
        label : 'Zona',
        to    : { name: OrdersRoutesEnum.ORDERS_NEW_ZONE },
        class : this.$route.name === OrdersRoutesEnum.ORDERS_NEW_ZONE     
          ? 'p-highlight' : '',
      },

      this.me?.role !== UserRoleEnum.CUSTOMER_ADMIN ?
      {
        label : 'Cliente',
        to    : { name: OrdersRoutesEnum.ORDERS_NEW_CUSTOMER },
        class : this.$route.name === OrdersRoutesEnum.ORDERS_NEW_CUSTOMER 
          ? 'p-highlight' : '',
        disabled: () => disabeldIf("zone")
      } : null,

      {
        label : 'Ritiro',
        to    : { name: OrdersRoutesEnum.ORDERS_NEW_PICKUP },
        class : this.$route.name === OrdersRoutesEnum.ORDERS_NEW_PICKUP   
          ? 'p-highlight' : '',
        disabled: () => disabeldIf("zone", "customer")
      },
      {
        label : 'Consegna',
        to    : { name: OrdersRoutesEnum.ORDERS_NEW_DROPOFF },
        class : this.$route.name === OrdersRoutesEnum.ORDERS_NEW_DROPOFF  
          ? 'p-highlight' : '',
        disabled: () => disabeldIf(
          "zone", "customer", 
            "pickup_picker", "pickup_picker_served"
          )
      },
      {
        label : 'Dati ordine',
        to    : { name: OrdersRoutesEnum.ORDERS_NEW_DATA },
        class : this.$route.name === OrdersRoutesEnum.ORDERS_NEW_DATA     
          ? 'p-highlight' : '',
        disabled: () => disabeldIf(
            "zone", "customer", "pickup_picker", 
            "dropoff_picker", "dropoff_picker_served"  )
      } ,
      {
        label : 'Riepilogo',
        to    : { name: OrdersRoutesEnum.ORDERS_NEW_COMPLETE },
        class : this.$route.name === OrdersRoutesEnum.ORDERS_NEW_COMPLETE 
          ? 'p-highlight' : '',
        disabled: disabeldIf(
          "zone", "customer", "pickup_picker", 
          "dropoff_picker", "shift_id", "vehicle" )
      }
    ].filter(x => x);
  }

  get transitionName(): string {
    return (this.$route.meta?.transitionName as string) || "slide"; 
  }

  created() {
    const isCustomer = [
      UserRoleEnum.CUSTOMER_ADMIN, 
      UserRoleEnum.CUSTOMER_USER
    ].includes(this.me?.role);


    if (isCustomer && ordersStoreModule.getters.newOrder?.customer?.id !== this.me.entity_id) {
      ordersStoreModule.mutations.setCustomer(this.me.entity as Customer);
    }

    const { loadGoogle } = useGoogleLoader('it');
    loadGoogle();
  }
}