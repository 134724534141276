import { useMessage }                  from "@plugins/Messages-plugin";
import { Customer }                    from "@/model/Customer";
import { OrdersRoutesEnum }            from "@/modules/orders/router";
import { ordersStoreModule }           from "@/modules/orders/store";
import { useAutocompleteAndGetDetail } from "@/use/useAutocomplete";
import EntityForm                      from "@components/EntityForm/EntityForm.vue";
import { customersService }            from "@services/customers.service";
import { useRouter }                   from "vue-router";
import { useI18n }                     from "vue-i18n";
import CustomerForm                    from "@components/CustomerForm/CustomerForm.vue";

import { CustomerSummary } from '../../../components';

import { defineComponent, getCurrentInstance, nextTick, onErrorCaptured, onMounted, reactive, ref, toRefs } from "vue";

import { Form as VForm } from 'vee-validate';
import { useNext }       from "../logic/useNext";
import { PaymentType }   from "@/model/Entity";
import { Error422 }      from "@/model/common/Error422";


export default defineComponent( {
    inheritAttrs: false,

    components: {
        VForm,
        EntityForm,
        CustomerSummary,
        CustomerForm,
    },

    beforeRouteEnter( _, __, next ) {
        if (!ordersStoreModule.getters.newOrder?.zone) {
            next( { name: OrdersRoutesEnum.ORDERS_NEW_ZONE } );
        } else {
            next();
        }
    },

    setup( _ ) {
        const errorMessage = ref<string>( null );

        const instance = getCurrentInstance();

        const state = reactive( {
            displayNewCustomer: false,
            newCustomer       : new Customer(),
            showError         : false
        } );

        function toggleSidebar() {
            state.displayNewCustomer = !state.displayNewCustomer;
        }

        const r = useRouter();

        const { successMessage, errorMessage: showErrorMessage } = useMessage();

        const { t } = useI18n();

        /**
         * Click button "save" on sidebar
         * or submit its form
         */
        async function onSave( customer: Customer ) {
            await saveNewCustomer( customer )
        }

        async function saveNewCustomer( customer: Customer ) {
            const response = await customersService.create( customer );

            selected.value = response;

            if (response.payment_type === PaymentType.ANTICIPATED) {
                errorMessage.value = `
                Non è possibile creare un ordine per questo cliente.
                Il cliente ${ response.business_name } ha pagamento anticipato.
                `;

                ordersStoreModule.mutations.setCustomer( null );
            } else {
                errorMessage.value = null;
                ordersStoreModule.mutations.setCustomer( response );
            }

            searchBox.value = null;
            toggleSidebar();
            successMessage( t( 'message.success_message' ) );
        }

        function handle422( { errors }: Error422 ) {
            const fieldsInError = Object.keys( errors )

            function flattenString( a: string[] ) {
                return a?.join( " " )
            }

            if (fieldsInError?.length) {
                fieldsInError.forEach( k => {
                    showErrorMessage(
                        flattenString( errors[k] )
                    )
                } )
            }
        }

        onErrorCaptured( ( e: any ) => {
            if (e.status === 422) {
                handle422( e.data );
                return false
            }
        } )

        const {
                  onSelect,
                  search,
                  searchBox,
                  suggestions,
                  selected
              } = useAutocompleteAndGetDetail<Customer>( {
            service    : customersService,
            afterSelect: ( customer: Customer ) => {
                if (customer.payment_type === PaymentType.ANTICIPATED) {
                    errorMessage.value = `
            Non è possibile creare un ordine per questo cliente.  
            Il cliente ${ customer.business_name } ha pagamento anticipato.
          `;

                    ordersStoreModule.mutations.setCustomer( null );
                } else {
                    errorMessage.value = null;
                    ordersStoreModule.mutations.setCustomer( customer );
                }
            }
        } );

        const {
                  goNext,
                  isValid,
                  autocompleteRef,
                  onEnter
              } = useNext(
            OrdersRoutesEnum.ORDERS_NEW_PICKUP,
            ordersStoreModule.getters.newOrder,
            [
                "zone",
                "customer"
            ]
        )

        onMounted( () => {
            const c = ordersStoreModule.getters.newOrder?.customer;

            selected.value  = c;
            searchBox.value = c?.name;

            nextTick( () => {
                autocompleteRef.value.focus()
            } )
        } )

        return {
            onSave,

            ...toRefs( state ),

            toggleSidebar,

            autocompleteRef,
            goNext,
            isValid,
            onEnter,
            errorMessage,

            // Autocomplete
            onSelect,
            search,
            searchBox,
            suggestions,
            selected,
        }
    },


} );
