import { nextTick }                       from "vue";
import { FilterMatchMode }                from "primevue/api";
import { Options, Vue }                   from 'vue-property-decorator';

import { EntityPickerDialog }             from "@/components";

import { 
  EntityPicker, 
  EntityPickerType, 
  PickerSummaryModel 
} from "@/model/Entity";

import { EntityPickersFilter }            from "@/model/filters/EntityPickersFilter";
import { OrdersRoutesEnum }               from "@/modules/orders/router";
import { ordersStoreModule }              from "@/modules/orders/store";
import { checkPointInShape }              from "@/use/useIsServed";
import { entityPickersService }           from "@services/entityPickers.service";

import { PickerSummary } from "../../../components";


@Options({
  components: {
    EntityPickerDialog,
    PickerSummary
  },
  beforeRouteEnter(_, __, next){
    if (!ordersStoreModule.getters.newOrder?.pickup_picker) {
      next({name: OrdersRoutesEnum.ORDERS_NEW_CUSTOMER});
    } else  {
      next(); 
    }
  }
})
export default class NewOrderDropoff extends Vue {

  searchBox  : string | EntityPicker = null;
  suggestions: EntityPicker[]        = null;

  displayPickerDialog: boolean = false;

  get prevRoute() {
    return { name: OrdersRoutesEnum.ORDERS_NEW_PICKUP };
  }
  get nextRoute() {
    return { name: OrdersRoutesEnum.ORDERS_NEW_DATA };
  }

  get savePicker() {
    return this.order?.is_save_new_dropoff;
  }
  set savePicker(value: boolean) {
    ordersStoreModule.mutations.setSaveNewDropoff(value);
  }

  get order() {
    return ordersStoreModule.getters.newOrder;
  }

  get customer() {
    return ordersStoreModule.getters.customer;
  }
  
  get zone() {
    return this.order?.zone; 
  }
  
  get shape() {
    return ordersStoreModule.getters.lastShapeFromZone;
  }

  get selected(){
    return this.order.dropoff_picker;
  }

  get selectedPickerId(){
    return this.order.dropoff_picker_id;
  }

  get pickerType() {
    return EntityPickerType.DROPOFF;
  }
  
  get pickerSummary() {
    return PickerSummaryModel.fromEntity(this.selected);
  }  

  get isServed(){
    if (!this.selected) return true; 
    
    const {
      lat, 
      lon: lng
    } = this.selected;
    
    return checkPointInShape(
      new google.maps.LatLng({lat, lng}),
      this.shape
    )
  }
  
  onSelect({ value }) {
    ordersStoreModule.mutations.setPickerDropoff(value);
    ordersStoreModule.mutations.updateOrder({
      dropoff_picker_served: this.isServed
    })
  }

  onNew() {
    this.displayPickerDialog = true;
  }

  onSaveNewPicker(data: EntityPicker){
    ordersStoreModule.mutations.setPickerDropoff(data);
  }

  async search({ query }) {
    const params           = new EntityPickersFilter();
          params.entity_id = this.customer.id;
          params.q         = query;

    params.filters = {
      type: {
        value: EntityPickerType.DROPOFF,
        matchMode: FilterMatchMode.EQUALS
      }
    };
    
    const response = await entityPickersService.index(params);

    this.suggestions = response.data;
  }
  
  goNext(){
    this.$router.push(this.nextRoute);
  }
  
  onEnter() {
    if(this.selected && this.isServed){
      this.goNext();
    }
  }

  mounted() {
    this.searchBox = this.order!.dropoff_picker?.name;
    
    nextTick(() => {
      (this.$refs.autocompleteRef as HTMLElement).focus();
    })
  }
}