import { FilterMatchMode }                from "primevue/api";
import { Options, Vue }                   from 'vue-property-decorator';

import { authStore }                      from "@/modules/auth/store";
import { OrdersRoutesEnum }               from "@/modules/orders/router";
import { ordersStoreModule }              from "@/modules/orders/store";

import { checkPointInShape }              from "@/use/useIsServed";

import { UserRoleEnum }                   from "@/model/User";
import { EntityPicker, EntityPickerType, PickerSummaryModel } from "@/model/Entity";
import { EntityPickersFilter }            from "@/model/filters/EntityPickersFilter";

import { entityPickersService }           from "@services/entityPickers.service";

import { EntityPickerDialog }             from "@/components";

import { PickerSummary } from "../../../components";
import { nextTick } from "vue";

@Options({
  components: {
    EntityPickerDialog,
    PickerSummary
  },
  beforeRouteEnter(_, __, next){

    switch (authStore.getters.userRole) {
      case UserRoleEnum.SUPER_ADMIN:
        if (!ordersStoreModule.getters.newOrder?.customer) {
          next({name: OrdersRoutesEnum.ORDERS_NEW_CUSTOMER});
          return;
        }
        break;

      case UserRoleEnum.CUSTOMER_ADMIN:
      case UserRoleEnum.CUSTOMER_USER:
        if (!ordersStoreModule.getters.newOrder?.zone) {
          next({name: OrdersRoutesEnum.ORDERS_NEW_ZONE});
          return;
        }
        break;
    }

    next(); 
  }
})
export default class NewOrderPickup extends Vue {

  searchBox  : string | EntityPicker = null;
  suggestions: EntityPicker[]        = null;

  displayPickerDialog: boolean = false;

  get prevRoute() {
    return { name: OrdersRoutesEnum.ORDERS_NEW_CUSTOMER };
  }
  get nextRoute() {
    return { name: OrdersRoutesEnum.ORDERS_NEW_DROPOFF };
  }

  get savePicker() {
    return this.order?.is_save_new_pickup;
  }
  set savePicker(value: boolean) {
    ordersStoreModule.mutations.setSaveNewPickup(value);
  }

  get order() {
    return ordersStoreModule.getters.newOrder;
  }

  get customer() {
    return ordersStoreModule.getters.customer;
  }

  get zone() {
    return this.order?.zone; 
  }
  
  get shape() {
    return ordersStoreModule.getters.lastShapeFromZone;
  }

  get selected(){
    return this.order.pickup_picker;
  }

  get selectedPickerId(){
    return this.order.pickup_picker_id;
  }

  get pickerType() {
    return EntityPickerType.PICKUP;
  }

  get pickerSummary() {
    return PickerSummaryModel.fromEntity(this.selected);
  }  

  get isServed(){
    const {
      lat, 
      lon: lng
    } = this.selected;
    
    return checkPointInShape(
      new google.maps.LatLng({lat, lng}),
      this.shape
    )
  }

  onSelect({ value }: { value: EntityPicker }) {
    ordersStoreModule.mutations.setPickerPickup(value);
    ordersStoreModule.mutations.updateOrder({
      pickup_picker_served: this.isServed
    })
  }

  onNew() {
    this.displayPickerDialog = true;
  }

  onSaveNewPicker(data: EntityPicker){
    ordersStoreModule.mutations.setPickerPickup(data);
  }

  async search({ query }) {
    const params           = new EntityPickersFilter();
          params.entity_id = this.customer.id;
          params.q         = query;

    params.filters = {
      type: {
        value: EntityPickerType.PICKUP,
        matchMode: FilterMatchMode.EQUALS
      }
    };

    const response = await entityPickersService.index(params);

    this.suggestions = response.data;
  }
  
  goNext(){
    this.$router.push(this.nextRoute);
  }

  onEnter() {
    if(this.selected && this.isServed){
      this.goNext();
    }
  }

  mounted(): void {
    this.searchBox = this.selected?.name;

    nextTick(() => {
      (this.$refs.autocompleteRef as HTMLElement).focus();
    })
  }
}