
import { Zone } from "@/model/Zone";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        zone: {
            type: Zone
        }
    }
})
