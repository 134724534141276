import { FilterMatchMode } from "primevue/api";
import { computed, defineComponent, nextTick, onMounted, ref }             from "vue";

import { UserRoleEnum }                from "@/model/User";
import { authStore }                   from "@/modules/auth/store";
import { OrdersRoutesEnum }            from "@/modules/orders/router";
import { ordersStoreModule }           from "@/modules/orders/store";
import { useAutocompleteAndGetDetail } from "@/use/useAutocomplete";
import { zonesService }                from "@services/zones.service";
import ZoneSummary                     from '../ZoneSummary/ZoneSummary.vue';
import { useNext } from "../logic/useNext";

export default defineComponent({
  components: {
    ZoneSummary
  },

  setup(){
    const currentRole = computed(
      () => authStore.getters.userRole 
    )

    const {
      onSelect,
      search,
      searchBox,
      selected,
      suggestions
    } = useAutocompleteAndGetDetail({
      service: zonesService,
      params: {
        is_active: {
          value: true,
          matchMode: FilterMatchMode.EQUALS
        }
      },
      afterSelect: (zone) => {
        ordersStoreModule.mutations.setZone(zone)
      }
    })

    const {
      goNext,
      isValid,
      autocompleteRef,
      onEnter
    } = useNext(
      currentRole.value !== UserRoleEnum.SUPER_ADMIN 
        ? OrdersRoutesEnum.ORDERS_NEW_PICKUP
        : OrdersRoutesEnum.ORDERS_NEW_CUSTOMER,
      ordersStoreModule.getters.newOrder,
      ['zone']
    )

    onMounted(() => {
      const z = ordersStoreModule.getters.newOrder?.zone;
      
      selected.value  = z;
      searchBox.value = z?.name;
      onSelect({value: z});

      nextTick(() => {
        autocompleteRef.value.focus()
      })
    })

    return {
      onSelect,
      search,
      searchBox,
      selected,
      suggestions,

      autocompleteRef,
      onEnter,
      goNext, 
      isValid
    }
  }
});