import { computed, ref } from "vue"
import { useRouter } from "vue-router"

export function useNext<T>(
  nextRoute: string,
  obj: T,
  requiredKeys: (keyof T)[]
) {
  const autocompleteRef = ref<HTMLElement>()

  const router = useRouter()

  const isValid = computed(() => {
    return requiredKeys.every(key => obj[key])
  }) 

  function onEnter() {
    if(isValid.value){
      goNext()
    }
  }

  function goNext(){
    router.push({ name: nextRoute })
  }

  return {
    autocompleteRef,
    onEnter,
    goNext,
    isValid
  }
}