import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "page--limited",
  id: "new_order"
}
const _hoisted_2 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Steps = _resolveComponent("Steps")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Steps, {
        model: _ctx.items,
        readonly: false
      }, null, 8, ["model"])
    ]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({Component}) => [
        _createVNode(_Transition, {
          appear: "",
          name: _ctx.transitionName,
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1032, ["name"])
      ]),
      _: 1
    })
  ]))
}