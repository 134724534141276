import { Options, Vue } from "vue-class-component";
import { loadStripe } from '@stripe/stripe-js';
import { classToClass } from "class-transformer";

import { OrdersRoutesEnum }       from "@/modules/orders/router";
import { ordersStoreModule }      from "@/modules/orders/store";
import { ordersService }          from "@services/orders.service";
import { suppliersService }       from "@services/suppliers.service";
import { PaymentType, PickerSummaryModel, UserType }               from "@/model/Entity";
import { Supplier }               from "@/model/Supplier";
import { BaseApiFilter }          from "@/model/filters/BaseApiFilter";
import { Order }                  from "@/model/Order";

import { 
  CustomerSummary,
  OrderData,
  PickerSummary,
  PriceAndCostSummary ,
} from "../../../components";

import { configuration } from "@plugins/Config-plugin";
import { paymentService } from "@services/payment.service";

@Options({
  components: {
    OrderData,
    PickerSummary,
    CustomerSummary,
    PriceAndCostSummary
  },
  
  beforeRouteEnter(_, __, next) {
    const hasNotCustomer = !ordersStoreModule.getters.newOrder?.customer;
    const hasNotShift    = !ordersStoreModule.getters.newOrder?.shift_id;

    if (hasNotCustomer ) {
      next({ name: OrdersRoutesEnum.ORDERS_NEW_CUSTOMER });
    } 
    else if (hasNotShift) {
      next({ name: OrdersRoutesEnum.ORDERS_NEW_DATA});
    }
    else {
      next();
    }
  }
})
export default class NewOrderSummary extends Vue {

  saveResponse: Order = null; 

  get order() {
    return ordersStoreModule.getters.newOrder;
  }

  get pickupPicker() {
    return PickerSummaryModel.fromEntity(this.order.pickup_picker);
  }

  get dropoffPicker() {
    return PickerSummaryModel.fromEntity(this.order.dropoff_picker);
  }

  get note_supplier() {
    return this.order.note_supplier;
  }
  set note_supplier(note_supplier: string) {
    ordersStoreModule.mutations.updateOrder({
      ...this.order,
      note_supplier
    })
  }

  get note_customer() {
    return this.order.note_customer;
  }
  set note_customer(note_customer: string) {
    ordersStoreModule.mutations.updateOrder({
      ...this.order,
      note_customer
    })
  }
  
  get note_tmt() {
    return this.order.note_tmt;
  }
  set note_tmt(note_tmt: string) {
    ordersStoreModule.mutations.updateOrder({
      ...this.order,
      note_tmt
    })
  }

  private goToList() {
    return this.$router.replace({name: OrdersRoutesEnum.ORDERS_LIST});
  }

  savingInProgress: boolean = false;

  async saveAndPay() {
    try {
      configuration.isLoading = true; 
      this.savingInProgress = true;    

      const newOrder: Order = await this.save();

      if ( this.isCustomer && newOrder.payment_amount > 0) {
        if (this.me?.entity.payment_type === PaymentType.ANTICIPATED) {
          await this.useStripe(newOrder.id);
        } else {
          await this.goToList()
        }
      }

    } catch (error) {
      this.$errorMessage("Creazione sessione Stripe non riuscita");      
    } finally {
      this.savingInProgress = false;
      configuration.isLoading = false; 
    }
  }

  async save(){
    return this.$waitFor(
      async () => {
        const response = await ordersService.create(this.order.toCreationRequest());
        this.$successMessage("Ordine creato con successo");
        this.saveResponse = response;
        return this.saveResponse
      },
      "Creazione ordine interrotta"
    )
  }

  private async useStripe(orderId: number) {
    try {
      this.requestPending = true;
      const stripe = await loadStripe(configuration.stripeKey);     
      localStorage.setItem('payment_order', orderId.toString());
      const { session_id: sessionId } = 
        await paymentService.checkoutSession(orderId);
      stripe.redirectToCheckout({sessionId}).then(alert);
    } catch (error) {
      this.$errorMessage("Errore creazione sessione STRIPE");
    } finally {
      this.requestPending = false;
    }
  }

  async restart() {
    const clone = classToClass(this.order);
    /**
     * Reset di alcuni campi dell'ordine
     */
    clone.pickup_priority       = false;
    clone.dropoff_priority      = false;
    clone.external_client_code  = null;
    
    await this.$router.replace({name: OrdersRoutesEnum.ORDERS_NEW_DROPOFF});
    ordersStoreModule.mutations.updateOrder(clone.resetDropoff());
  }

  async update(){
    return this.$waitFor(
      async () => {
        const response = await ordersService.update(this.saveResponse);

        this.saveResponse = response;

        this.$successMessage("Ordine aggiornato");
      },
      "Aggiornamento ordine interrotta"
    )
  }


  // GESTIONE ASSEGNAZIONE 

  get supplierValue(){
    return UserType.SUPPLIER;
  }

  get takerValue(){
    return UserType.TAKER;
  }

  entityType       : UserType          = null;
  showAssignDialog : boolean           = false;
  searchBox        : string | Supplier = null;
  suggestions      : Supplier[]        = null;
  selected         : Supplier          = null;

  saveAndAssign(){
    this.showAssignDialog = true; 
  }
  closeDialog(){
    this.showAssignDialog = false; 
  }

  onHide(){
    this.searchBox  = null;
    this.selected   = null;
    this.entityType   = null; 
  }

  async search({ query }) {
    const f = new BaseApiFilter();
    f.q = query;

    const response = await suppliersService.index(f);

    this.suggestions = response.data;
  }

  onSelect({ value }) {
    this.selected = value;
  }

  async onConfirm(){
    const update = {...this.order};
    update.entity_type = this.entityType;

    if (update.entity_type === this.supplierValue) {
      update.supplier  = this.selected;
      update.entity_id = this.selected.id;
    } else  {
      update.entity_id = null; 
    }

    ordersStoreModule.mutations.updateOrder(update);

    await this.save();

    this.closeDialog(); 

  }
}