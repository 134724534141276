import { Options, Vue }      from "vue-class-component";

import { rxs }               from "@sharedUtils/utils";

import { OrdersRoutesEnum }  from "@/modules/orders/router";
import { ordersStoreModule } from "@/modules/orders/store";

import { customersService }  from "@services/customers.service";

import { Vehicle }           from "@/model/Vehicle";
import { Shift }             from "@/model/Shift";

import {
  TimeslotSelect
} from "@/components"
import { TimeScale } from "chart.js";
import moment from "moment";

@Options({
  components:  {
    TimeslotSelect
  },
  
  beforeRouteEnter(_, __, next){
    if (!ordersStoreModule.getters.newOrder?.dropoff_picker) {
      next({name: OrdersRoutesEnum.ORDERS_NEW_CUSTOMER});
    } else  {
      next(); 
    }
  }
})
export default class NewOrderData extends Vue {
  vehicleOptions: Vehicle[] = null; 

  timeslot: number = null; 

  get minTime() {
    return this.selectedShift?.start_time;
  }

  get maxTime() {
    return this.selectedShift?.end_time;
  }

  get minDateValue(){
    return new Date(); 
  }
  
  get prevRoute() {
    return { name: OrdersRoutesEnum.ORDERS_NEW_DROPOFF };
  }
  get nextRoute() {
    return { name: OrdersRoutesEnum.ORDERS_NEW_COMPLETE };
  }

  get order(){
    return ordersStoreModule.getters.newOrder;
  } 

  get refSenderName() {
    return this.order?.ref_sender_name;
  }

  set refSenderName(ref_sender_name: string) {
    ordersStoreModule.mutations.updateOrder({
      ref_sender_name
    });
  }

  get refSenderPhone() {
    return this.order?.ref_sender_phone;
  }

  set refSenderPhone(ref_sender_phone: string) {
    ordersStoreModule.mutations.updateOrder({
      ref_sender_phone
    });
  }

  get extexternalClientCode() {
    return this.order?.external_client_code; 
  } 

  set extexternalClientCode(value: string) {
    ordersStoreModule.mutations.setExternalCode(value);
  }

  get vehicle(): Vehicle{
    return this.order?.vehicle;
  }

  set vehicle(value: Vehicle){
    ordersStoreModule.mutations.setVehicle(value);
  }

  onVehicleChange() {
    if (!this.vehicle?.is_package) {
      this.quantity = 1;
    }
  }

  get pickupDatetime(){
    return this.order.pickup_datetime;
  }

  set pickupDatetime(pickup_datetime){
    ordersStoreModule.mutations.updateOrder({
      ...this.order,
      pickup_datetime
    });
  }

  get dropoffDatetime(){
    return this.order.dropoff_datetime;
  }
  
  set dropoffDatetime(val: Date){
    let hours, minutes = null; 

    if (val instanceof Date) {
      hours   = val.getHours();
      minutes = val.getMinutes();
    } else {
      return;
    }

    const dropoff_datetime = new Date(this.pickupDatetime);

    dropoff_datetime.setHours(hours);
    dropoff_datetime.setMinutes(minutes);

    ordersStoreModule.mutations.updateOrder({
      ...this.order,
      dropoff_datetime
    });
  }

  get quantity(){
    return this.order.items_qty;
  }
  set quantity(items_qty){
    ordersStoreModule.mutations.updateOrder({
      ...this.order,
      items_qty
    });
  }

  get pickup_priority(): boolean {
    return this.order.pickup_priority; 
  }
  set pickup_priority(pickup_priority){
    ordersStoreModule.mutations.updateOrder({
      ...this.order,
      pickup_priority
    });
  }

  get dropoff_priority(): boolean {
    return this.order.dropoff_priority; 
  }
  set dropoff_priority(dropoff_priority){
    ordersStoreModule.mutations.updateOrder({
      ...this.order,
      dropoff_priority
    });
  }

  private updatePickupTime() {
    if (!this.selectedShift) return 
    const [h, m, s] = this.selectedShift.start_time.split(':');
    this.pickupDatetime.setHours(+h, +m, +s);
  }

  private updateDropoffTime() {
    if (!this.selectedShift) return 
    const [eh, em, es] = this.selectedShift.end_time.split(':');
    const d = new Date(this.pickupDatetime)
    d?.setHours(+eh, +em, +es);
    this.dropoffDatetime = d; 
  }

  selectedShift: Shift = null; 
  onTimeslotChange({value: shift_id}) {
    this.selectedShift = this.$store.getters.shiftById(shift_id)

    ordersStoreModule.mutations.updateOrder({
      ...this.order,
      shift_id,
      shift: this.selectedShift
    });

    this.updatePickupTime();
    this.updateDropoffTime();
  }
  
  goNext(){
    this.$router.push(this.nextRoute);
  }

  private async loadVehicleOptions(){
    const { customer, zone } = this.order;
    this.vehicleOptions = await customersService.getAvailableVehicles(customer.id, zone.id);
  }

  private initEmergencyContacts() {
    const {
      name, surname, business_tel
    } = this.order?.customer; 

    this.refSenderName  ??= `${name} ${surname}`;
    this.refSenderPhone ??= business_tel;
  }

  get noPastSlots() {
    return moment().isSame(this.pickupDatetime, 'day');
  }

  async mounted(){
    this.loadVehicleOptions(); 
    this.initEmergencyContacts();

    if (this.order?.shift) {
      this.timeslot      = this.order.shift.id;
      this.selectedShift = this.order.shift;
    }
  }

}